/* Miss Cacao 3d */

$initial-opacity: 0.6;
$mid-opacity: 1;
$final-opacity: 1;

@include keyframes(glow-pulse) {
  0% {
    opacity: $initial-opacity;
  }
  50% {
    opacity: $final-opacity;
  }
  100% {
    opacity: $initial-opacity;
  }
}

@include keyframes(perspective-shift) {
  0% {
    perspective: 6000px;
  }
  50% {
    perspective: 1000px;
  }
  100% {
    perspective: 6000px;
  }
}

@include keyframes(rotation-circle) {
  0% {
    transform: rotateX(15deg) rotateY(0deg);
  }
  12.5% {
    transform: rotateX(15deg) rotateY(15deg);
  }
  25% {
    transform: rotateX(0deg) rotateY(15deg);
  }
  37.5% {
    transform: rotateX(-15deg) rotateY(15deg);
  }
  50% {
    transform: rotateX(-15deg) rotateY(0deg);
  }
  62.5% {
    transform: rotateX(-15deg) rotateY(-15deg);
  }
  75% {
    transform: rotateX(0deg) rotateY(-15deg);
  }
  87.5% {
    transform: rotateX(15deg) rotateY(-15deg);
  }
  100% {
    transform: rotateX(15deg) rotateY(0deg);
  }
}

@include keyframes(opacity-throb) {
  0% {
    opacity: $initial-opacity;
  }
  20% {
    opacity: $mid-opacity;
  }
  40% {
    opacity: $initial-opacity;
  }
  60% {
    opacity: $mid-opacity;
  }
  80% {
    opacity: $initial-opacity;
  }
  100% {
    opacity: $initial-opacity;
  }
}
/*
.glow {
  opacity:var(--glow-opacity, 0);
}

*/

.glow {
  animation-name: opacity-throb;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
/*
#miss-cacao-background-wrapper {
  animation-name:perspective-shift;
  animation-duration:6s;
  animation-timing-function:ease-in-out;
  animation-iteration-count:infinite;
}
*/
#miss-cacao {
  perspective: 4000px;
  overflow: visible;
}
#chocolate-bar-background-rotater {
  @media only screen and (max-height: 736px) and (max-aspect-ratio: 9/16) {
    top: -2.5%;
  }
}
#miss-cacao-background-rotater {
  //animation-name:rotation-circle;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  @media only screen and (max-height: 736px) and (max-aspect-ratio: 9/16) {
    top: -5%;
  }
}

#cacao-day-background-rotater {
  @media only screen and (max-height: 736px) and (max-aspect-ratio: 9/16) {
    top: -5%;
  }
}
@for $i from 1 through 59 {
  #miss-cacao image:nth-child(#{$i}) {
    transform: translateZ(50px * $i);
  }
}
.interactive {
  transform: rotateX(deg) rotateY(0deg) scale(1);
}
