$duration: 300ms;

.fade-enter-done {
  .interactive img {
    opacity: 1;
    z-index: 1;
  }
}
.hero-gradient {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.fade-enter {
  .hero-gradient {
    opacity: 0;
  }
  .interactive img {
    opacity: 0;
    z-index: 1;
  }
}

.fade-enter.fade-enter-active {
  .supplemental-layer {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
  .hero-gradient {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
  .interactive img {
    opacity: 1;
    transition: opacity 1000ms ease-in;
  }
  @for $i from 1 through 60 {
    #chocolate-bar-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * ($i - 60);
    }
  }
  @for $i from 1 through 60 {
    #miss-cacao-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * ($i - 60);
    }
  }
  @for $i from 1 through 60 {
    #bridge-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * ($i);
    }
  }
  @for $i from 1 through 60 {
    #cacao-day-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * ($i - 60);
    }
  }
}
.fade-exit {
  .glow {
    animation-name: none;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    transition: opacity 1000ms ease-in;
    opacity: 0;
  }
}
.fade-exit.fade-exit-active {
  .supplemental-layer {
    opacity: 0;
    transition: opacity 1000ms ease-in;
  }
  .hero-gradient {
    opacity: 0;
    transition: opacity 1000ms ease-in;
  }
  .interactive img {
    opacity: 0;
    transition: opacity 1000ms ease-in;
  }
  @for $i from 1 through 60 {
    #chocolate-bar-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * $i;
    }
  }
  @for $i from 1 through 60 {
    #miss-cacao-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * $i;
    }
  }
  @for $i from 1 through 60 {
    #bridge-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * $i;
    }
  }
  @for $i from 1 through 60 {
    #cacao-day-background-rotater img:nth-child(#{$i}) {
      transition-delay: 25ms * $i;
    }
  }
}

.movable-div {
  transform: translateY(0%);
}
$cubicEasing: cubic-bezier(0.645, 0.045, 0.355, 1);
$quadEasing: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easing: $quadEasing;
.scrolling-down {
  .fade-enter-done {
    &.movable-div {
      transform: translateY(0%);
    }
  }
  .fade-enter {
    &.movable-div {
      transform: translateY(100%);
    }
  }
  .fade-enter.fade-enter-active {
    &.movable-div {
      transition: all 333ms $easing;
      transform: translateY(0%);
    }
  }
  .fade-exit.fade-exit-active {
    &.movable-div {
      transition: all 333ms $easing;
      transform: translateY(-100%);
    }
  }
}

.scrolling-up {
  .fade-enter-done {
    &.movable-div {
      transform: translateY(0%);
    }
  }
  .fade-enter {
    &.movable-div {
      transform: translateY(-100%);
    }
  }
  .fade-enter.fade-enter-active {
    &.movable-div {
      transition: all 333ms $easing;
      transform: translateY(0%);
    }
  }
  .fade-exit.fade-exit-active {
    &.movable-div {
      transition: all 333ms $easing;
      transform: translateY(100%);
    }
  }
}

.text-fade-enter {
  opacity: 0;
  transform: translateY(50px);
}
.text-fade-enter.text-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease-in-out;
}
.text-fade-exit.text-fade-exit-active {
  opacity: 0;
  transform: translateY(50px);
}

.scroll-modules {
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
}

.mobile-swipeable {
  height: 100%;
  width: 100%;
  position: absolute;
}
/*
#labor-graph path.graph-line {
  stroke-dasharray: 2000;
}
.fade-enter #labor-graph path.graph-line {
  stroke-dashoffset: 0;
}
.fade-enter-active #labor-graph path.graph-line,
.fade-enter-done #labor-graph path.graph-line {
  transition: stroke-dashoffset 10s;
  stroke-dashoffset: -500;
}

.fade-enter #labor-graph circle.graph-end-circle {
  opacity: 0;
}
.fade-enter-active #labor-graph circle.graph-end-circle,
.fade-enter-done #labor-graph circle.graph-end-circle {
  transition: opacity 1s ease-in-out 3s;
  opacity: 1;
}

$bottom-duration: 600ms;
// transition: opacity $bottom-duration ease-in-out;
.mm-bottom {
  transition: opacity $bottom-duration ease-in-out;
  &.fade-appear {
    opacity: 0;
  }
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
  }
  &.fade-exit-done {
  }
}

.mm-middle {
  transition: opacity $duration * 2 cubic-bezier(0.215, 0.61, 0.355, 1),
    transform $duration * 2 cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  transform: translateY(0px);
  &.fade-appear {
    transform: translateY(50px);
    opacity: 0;
  }
  &.fade-enter {
    transform: translateY(50px);
    opacity: 0;
  }
  &.fade-exit {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
  &.fade-exit-active {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
  &.fade-exit-done {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
}

.mm-top-slide {
  transition: opacity $duration * 2 cubic-bezier(0.215, 0.61, 0.355, 1),
    transform $duration * 2 cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  transform: translateY(0px);
  &.slide-appear {
    transform: translateY(50px);
    opacity: 0;
  }
  &.slide-enter {
    transform: translateY(50px);
    opacity: 0;
  }
  &.slide-exit {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
  &.slide-exit-active {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
  &.slide-exit-done {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(0px);
  }
}
.mm-top-fade {
  transition: opacity $duration * 2 cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 1;
  &.fade-appear {
    opacity: 0;
  }
  &.fade-enter {
    opacity: 0;
  }
  &.fade-exit {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }
  &.fade-exit-active {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }
  &.fade-exit-done {
    transition: opacity $duration cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
  }
}
*/
