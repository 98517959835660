$standard_duration: 333ms;
/*.credit { */
#credit-root {
  transition: opacity $standard_duration ease-in-out;
  opacity: 0;
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-exit-done {
    opacity: 0;
  }
}

.credits-arrow-holder {
  transition: all 0.3s ease-out;
  transform: translateX(0%);
}
.credit-component {
  pointer-events: all;
  &:hover {
    .credit-title-text {
      color: #10cfab;
    }
    .credits-arrow-holder {
      transform: translateX(50%);
    }
  }
}
.credits-scroll-wrapper {
  overflow-y: scroll;
  pointer-events: all;
}

.credit-arrow {
}

#motion-root {
  transition: opacity $standard_duration ease-in-out;
  opacity: 0;
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-exit-done {
    opacity: 0;
  }
}
