$standard_duration: 333ms;
/*
.datasource {
  transition: opacity $standard_duration ease-in-out;
  opacity: 0;
  z-index: 0;
  &.fade-enter-active {
    z-index: 10001;
    opacity: 1;
  }
  &.fade-enter-done {
    z-index: 10001;
    opacity: 1;
  }
  &.fade-exit {
    z-index: 10001;
    opacity: 0;
  }
  &.fade-exit-active {
    z-index: 10001;
    opacity: 0;
  }
  &.fade-exit-done {
    z-index: 0;
    opacity: 0;
  }
}
*/
.datasource {
  transition: transform $standard_duration ease-in-out, opacity $standard_duration ease-in-out;
  position: absolute;
  @media screen and (max-width: 1023px) {
    opacity: 0;
  }
  @media screen and (min-width: 1024px) {
    opacity: 1;
    transform: translateY(67vh);
  }
  &.fade-enter-active {
    opacity: 1;
    z-index: 10002;
    @media screen and (min-width: 1024px) {
      transition: transform $standard_duration / 4 ease-in-out;
      transform: translateY(0px);
      z-index: 10002;
      opacity: 1;
    }
  }
  &.fade-enter-done {
    opacity: 1;
    z-index: 10002;
    @media screen and (min-width: 1024px) {
      transform: translateY(0px);
      z-index: 10002;
      opacity: 1;
    }
  }
  &.fade-exit {
    @media screen and (max-width: 1023px) {
      opacity: 1;
      z-index: 10002;
    }
    @media screen and (min-width: 1024px) {
      transform: translateY(67vh);
      z-index: 10002;
    }
  }
  &.fade-exit-active {
    @media screen and (max-width: 1023px) {
      opacity: 1;
      z-index: 10002;
    }
    @media screen and (min-width: 1024px) {
      transform: translateY(67vh);
      z-index: 10002;
    }
  }
  &.fade-exit-done {
    @media screen and (max-width: 1023px) {
      opacity: 0;
      z-index: 0;
    }
    @media screen and (min-width: 1024px) {
      transform: translateY(67vh);
      z-index: 0;
      opacity: 1;
    }
  }
}
.datasource-bg {
  transition: opacity $standard_duration ease-out;
  opacity: 0;
  z-index: 0;
  &.fade-enter-active {
    z-index: 10001;
    opacity: 0.8;
  }
  &.fade-enter-done {
    z-index: 10001;
    opacity: 0.8;
  }
  &.fade-exit {
    z-index: 10001;
    opacity: 0.8;
  }
  &.fade-exit-active {
    z-index: 10001;
    opacity: 0;
  }
  &.fade-exit-done {
    z-index: 0;
    opacity: 0;
  }
}
