$standard_duration: 333ms;
/*.sponsor { */
#sponsor-root {
  transition: opacity $standard_duration ease-in-out;
  opacity: 0;
  &.fade-enter-active {
    opacity: 0;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-exit-done {
    opacity: 0;
  }
}
.sponsorheader {
  transition: opacity $standard_duration ease-in-out;
  opacity: 0;
  &.fade-enter-active {
    opacity: 0;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-exit-done {
    opacity: 0;
  }
}

.sponsor-container {
  scroll-snap-type: both mandatory;
  overflow-x: scroll;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
  pointer-events: all;
  @supports (-webkit-overflow-scrolling: touch) {
  }

  @supports not (-webkit-overflow-scrolling: touch) {
    /* CSS for other than iOS devices */
    padding-bottom: 15px;
    overflow-x: visible;
    overflow-y: visible;
  }
}

.sponsor-component {
  scroll-snap-align: start;
  position: relative;
  pointer-events: all;
}
