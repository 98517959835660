$initial-opacity: 0.8;
$mid-opacity: 0.2;

$large-scale: 1.75;
@include keyframes(large-pulse) {
  0% {
    opacity: $initial-opacity;
    transform: scale(1);
  }
  80% {
  }
  100% {
    opacity: 0;
    transform: scale($large-scale);
  }
}

.pulse-one {
  animation-name: large-pulse;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-iteration-count: infinite;

  transform: scale(1.75);
  opacity: 0.5;
}

$small-scale: 1.375;
@include keyframes(small-pulse) {
  0% {
    opacity: $initial-opacity;
    transform: scale(1);
  }
  80% {
  }
  100% {
    opacity: 0;
    transform: scale($small-scale);
  }
}

.pulse-two {
  animation-name: small-pulse;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-iteration-count: infinite;

  transform: scale(1.375);
  opacity: 0.5;
}

@include keyframes(large-pulse-fast) {
  0% {
    opacity: $initial-opacity;
    transform: scale(1);
  }
  80% {
  }
  100% {
    opacity: 0;
    transform: scale($large-scale);
  }
}

.pulse-one-fast {
  animation-name: large-pulse;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-iteration-count: infinite;

  transform: scale(1.75);
  opacity: 0.5;
}

$small-scale: 1.375;
@include keyframes(small-pulse-fast) {
  0% {
    opacity: $initial-opacity;
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) translateX(-50%) scale(1.25);
  }
}

.pulse-two-fast {
  animation-name: small-pulse;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-iteration-count: infinite;

  transform: scale(1.375);
  opacity: 0.5;
}

$large-scale: 1.75;
@include keyframes(large-border-pulse) {
  0% {
    opacity: $initial-opacity;
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) translateX(-50%) scale(2.25);
  }
}

@include keyframes(larger-border-pulse) {
  0% {
    opacity: 0.6;
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) translateX(-50%) scale(1.3);
  }
}
.pulse-border {
  animation-name: large-border-pulse;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-iteration-count: infinite;
  opacity: $initial-opacity;
  width: 33%;
  height: 33%;
  background-color: white;
}
.pulse-one-border {
  animation-delay: 0s;
}
.pulse-two-border {
  animation-delay: 0.625s;
}
.pulse-three-border {
  animation-delay: 1.25s;
}
.pulse-four-border {
  animation-delay: 1.875s;
}
.pulse-five-border {
  animation-delay: 2.5s;
}
.pulse-six-border {
  animation-delay: 3.125s;
}
.pulse-seven-border {
  animation-delay: 3.75s;
}
.pulse-eight-border {
  animation-delay: 4.4s;
}
.outline-border {
  border: 4px solid white;
  transition: all 0.3s;
  pointer-events: all;
  &:hover {
    border: 3px solid rgba(242, 186, 89, 1);
  }
}

#genetic-pulse {
  mask-image: url(./images/PulseMask.svg);
  webkit-mask-image: url(./images/PulseMask.svg);
  mask-mode: alpha;
  webkit-mask-mode: alpha;
  mask-repeat: no-repeat;
  webkit-mask-repeat: no-repeat;
  mask-size: 100%;
  webkit-mask-size: 100%;
  mask-position: center;
  webkit-mask-position: center;
}
/*

  .pulse-two-border {
    animation-name: larger-border-pulse;
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: infinite;
  }
  .dpulse-one-border {
    animation-name: large-border-pulse;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: infinite;
  }
  .dpulse-two-border {
    animation-name: larger-border-pulse;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: infinite;
  }
*/
