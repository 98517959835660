@font-face {
  font-family: GTAmerica;
  src: url("fonts/GT-America-Standard-Regular.woff2") format("woff2"),
    url("fonts/GT-America-Standard-Regular.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: GTAmerica;
  src: url("fonts/GT-America-Standard-Medium.woff2") format("woff2"),
    url("fonts/GT-America-Standard-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: GTAmerica;
  src: url("fonts/GT-America-Standard-Bold.woff2") format("woff2"),
    url("fonts/GT-America-Standard-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: GTAmerica;
  src: url("fonts/gt-america-light.woff") format("woff2");
  font-weight: 300;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: always;
}
