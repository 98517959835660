#river-village-birdseye-annotation {
  .st0 {
    fill: #eaeaea;
    fill-opacity: 0.2;
  }
  .st1 {
    fill: #ffffff;
  }
  .st2 {
    opacity: 0.75;
  }
  .bubble {
    cursor: pointer;
  }
  .bubble:hover {
    & .accessory-bubble {
      fill: #10cfab;
      transition: all 0.3s;
    }
  }
  #village-image-one,
  #village-image-two,
  #village-image-three,
  #village-image-four {
    cursor: pointer;
  }
}

#river-village-birdseye-annotation-mobile {
  .st0 {
    opacity: 0.75;
  }
  .st1 {
    fill: #eaeaea;
    fill-opacity: 0.2;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    fill: #ff701b;
  }
}
