// remove system default margins
body {
  overflow: hidden;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  background: #222222;
  position: fixed;
}

// Set 100% height on all parents of the root element.
html {
  height: 100%;
  position: fixed;
}
body {
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
}

body {
  touch-action: manipulation;
}
@viewport {
  zoom: 1;
  min-zoom: 1;
  max-zoom: 1;
}
:any-link {
  text-decoration: none !important;
  color: inherit;
  &:active {
    color: inherit;
  }
  &:visited {
    color: inherit;
  }
}

.scroll-manager::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
.scroll-manager::-webkit-scrollbar-thumb {
  background: #ff0000;
}

video.interactive-video::-webkit-media-controls {
  display: none !important;
  opacity: 0;
}

video.interactive-video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

video.interactive-video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

video.interactive-video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.datasource::-webkit-scrollbar {
  width: 10px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.datasource::-webkit-scrollbar-thumb {
  border-radius: 5px;
  overflow: hidden;
  background: #cccccc;
}

.credits-scroll-wrapper::-webkit-scrollbar {
  width: 8px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.credits-scroll-wrapper::-webkit-scrollbar-thumb {
  width: 8px;
  overflow: hidden;
  background: #cccccc;
}

iframe {
  pointer-events: all;
  @media screen and (max-width: 768px) {
    pointer-events: none;
    &#brewing {
      pointer-events: all;
    }
  }
  &.jungle {
    pointer-events: none;
  }
}
