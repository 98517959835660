$standard_duration: 333ms;
.header {
  transition: opacity $standard_duration ease-in-out;
  opacity: 1;
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-enter-done {
    opacity: 1;
  }
  &.fade-exit {
    opacity: 0;
  }
  &.fade-exit-active {
    opacity: 0;
  }
  &.fade-exit-done {
    opacity: 0;
  }
}
