$standard_duration: 333ms;
.menu {
  transition: transform $standard_duration ease-in-out;
  @media screen and (max-width: 1023px) {
    transform: translateX(320px);
  }
  @media screen and (min-width: 1024px) {
    transform: translateX(574px);
  }
  &.fade-enter-active {
    transition: transform $standard_duration / 4 ease-in-out;
    transform: translateX(0px);
  }
  &.fade-enter-done {
    transform: translateX(0px);
  }
  &.fade-exit {
    @media screen and (max-width: 1023px) {
      transform: translateX(320px);
    }
    @media screen and (min-width: 1024px) {
      transform: translateX(574px);
    }
  }
  &.fade-exit-active {
    @media screen and (max-width: 1023px) {
      transform: translateX(320px);
    }
    @media screen and (min-width: 1024px) {
      transform: translateX(574px);
    }
  }
  &.fade-exit-done {
    @media screen and (max-width: 1023px) {
      transform: translateX(320px);
    }
    @media screen and (min-width: 1024px) {
      transform: translateX(574px);
    }
  }
}
